'use-client';
import * as React from 'react';
import classnames from 'clsx';
import type { MouseEvent, KeyboardEvent } from 'react';
import {
  activateBySpaceOrEnterButton,
  getDataAttributes,
  performOnEnter,
} from '@wix/editor-elements-common-utils';
import type { IEnterPasswordDialogProps } from '../EnterPasswordDialog.types';
import { TRANS_KEYS, TEST_IDS } from '../constants';
import Close from './assets/close.svg';
import style from './style/EnterPasswordDialog.scss';

const EnterPasswordDialog: React.FC<IEnterPasswordDialogProps> = props => {
  const {
    id,
    className,
    translations,
    errorCode,
    isCloseable = true,
    onSubmitCallback,
    onCloseDialogCallback,
  } = props;
  const [prevErrorCode, setPrevErrorCode] = React.useState(errorCode);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [password, setPassword] = React.useState('');

  const updateErrorMessage = (newErrorMessage: string = '') =>
    setErrorMessage(newErrorMessage);

  const titleText = translations[TRANS_KEYS.title];
  const subtitleText = translations[TRANS_KEYS.subtitle];
  const submitButtonText = translations[TRANS_KEYS.submitButton];
  const passwordPlaceholder = translations[TRANS_KEYS.passwordPlaceholder];
  const closeButtonText = isCloseable
    ? translations[TRANS_KEYS.closeDialog]
    : '';

  if (prevErrorCode !== errorCode) {
    if (errorCode === 0) {
      updateErrorMessage();
    } else {
      const generalError = translations[TRANS_KEYS.generalError];
      const errorMsg =
        translations[`${TRANS_KEYS.errMsgPrefix}${errorCode}`.replace('-', '')];
      updateErrorMessage(errorMsg ?? generalError);
    }
    setPrevErrorCode(errorCode);
  }

  const onSubmit = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();

    if (!password) {
      updateErrorMessage(translations[TRANS_KEYS.blankPassword]);
    } else {
      setPrevErrorCode(0);
      onSubmitCallback(password);
    }
  };

  const onKeyDownHandler = performOnEnter(onSubmit);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classnames(style.enterPasswordContent, className)}
    >
      <div className={style.blockingLayer} />
      <div className={style.dialog}>
        <div className={style.title}>{titleText}</div>
        <form onKeyDown={onKeyDownHandler}>
          <div className={style.wrapper}>
            <div className={style.header}>
              <div className={style.subtitle}>{subtitleText}</div>
            </div>
            <div className={style.content}>
              <div
                data-testid={TEST_IDS.password}
                className={classnames(
                  style.password,
                  errorMessage && style.error,
                )}
              >
                <input
                  id={`${id}_input`}
                  placeholder={passwordPlaceholder}
                  autoFocus={true}
                  autoComplete="off"
                  type="password"
                  value={password}
                  onChange={event => {
                    setPassword(event.target.value);
                    if (errorMessage) {
                      updateErrorMessage();
                    }
                  }}
                  required
                />
              </div>
            </div>
            <div className={style.footer}>
              <p className={style.errMsg} data-testid={TEST_IDS.errorMessage}>
                {errorMessage}
              </p>
              <button
                type="submit"
                className={style.okButton}
                data-testid={TEST_IDS.submitButton}
                onClick={onSubmit}
              >
                {submitButtonText}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isCloseable && (
        <div
          className={style.xButton}
          tabIndex={0}
          role="button"
          data-testid={TEST_IDS.closeButton}
          aria-label={closeButtonText}
          onClick={onCloseDialogCallback}
          onKeyDown={activateBySpaceOrEnterButton}
        >
          <Close />
        </div>
      )}
    </div>
  );
};

export default EnterPasswordDialog;
